var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"order.trackings","resource":"/management/agency","creatable":"","deletable":""},on:{"click-clear-filter":_vm.clearFilter,"click-create":function($event){_vm.showModalCreate = true}},scopedSlots:_vm._u([{key:"received",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CIcon',{class:item.received ? 'text-success' : 'text-danger',attrs:{"name":item.received ? 'cil-check-circle' : 'cil-x-circle',"size":"custom","width":"17px"}})],1)]}},{key:"distributed",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CIcon',{class:item.distributed ? 'text-success' : 'text-danger',attrs:{"name":item.distributed ? 'cil-check-circle' : 'cil-x-circle',"size":"custom","width":"17px"}})],1)]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.created_at,"small":""}})],1)]}},{key:"reference",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((item.tracking_references),function(reference){return _c('TLink',{key:reference.tracking_id,attrs:{"content":reference.reference_id,"to":_vm.getReferenceLink(reference)}})}),1)]}},{key:"code-filter",fn:function(){return [_c('TInputText',{attrs:{"placeholder":"Tracking code","value":_vm.filter.code},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "code", $event)},_vm.filterChange]}})]},proxy:true},{key:"created_at-filter",fn:function(){return [_c('TInputDateTimeRange',{attrs:{"value":_vm.dateRange},on:{"update:value":[function($event){_vm.dateRange=$event},_vm.setCreatedAtFilter]}})]},proxy:true}])}),_c('TrackingModal',{attrs:{"show":_vm.showModalCreate},on:{"update:show":function($event){_vm.showModalCreate=$event},"created":function($event){_vm.showModalCreate = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }